<template>
  <CRow v-page-access="permissions.refundCreate">
    <CCol md="8" sm="8">
      <CCard>
        <CCardHeader class="pt-4">
          <h5>{{ $t("REFUND.CREATE.TITLE_MODAL") }}</h5>
        </CCardHeader>
        <CCardBody>
          <CForm name="NewPaymentLinkForm" id="NewPaymentLinkForm">
            <CRow align-vertical="center" v-show="showSearchPaymentForm">
              <CCol col="10">
                <CInput
                    class="col-12 px-0"
                    v-model="inputPaymentId"
                    :label="$t('REFUND.CREATE.PAYMENT_ID_LABEL')"
                />
              </CCol>
              <CCol col="2">
                <CButton
                    @click="handleSearch"
                    variant="outline"
                    color="durianprimary"
                    :disabled="inputPaymentId === null"
                    class="px-4 mt-2 primary-purple"
                >
                  {{ $t("REFUND.CREATE.SEARCH") }}
                </CButton>
              </CCol>
              <hr />
            </CRow>
            <div v-show="showNotFound">
              <CRow align-horizontal="center">
                <img class="mx-auto" src="../../assets/img/not-found.svg" />
                <CCol col="12">
                  <p class="mt-4 h5 text-center font-weight-bold">{{ $t("REFUND.CREATE.NOT_FOUND_TITLE") }}</p>
                  <p class="text-center text-muted">
                    {{ $t("REFUND.CREATE.NOT_FOUND_DESCRIPTION") }}
                  </p>
                </CCol>
              </CRow>
            </div>
            <div v-show="showPaymentDetail">
              <CRow>
                <CCol col="12">
                  <p class="font-weight-bold mb-2 header-title">{{ $t("REFUND.CREATE.TRANSACTION_DETAILS") }}</p>
                </CCol>
              </CRow>
              <CRow align-horizontal="between">
                <CCol col="6">
                  <CRow class="col-detail px">
                    <CCol col="4" class="pr-0"><p class="text-muted">{{ $t("REFUND.LABEL.PAYMENT_ID") }}</p></CCol>
                    <CCol col="8" class="pl-0"><p class="font-weight-bold text-link color-blue" @click="goTo(`/payments/${paymentId}`)">{{ paymentId }}</p></CCol>
                    <CCol col="4" class="pr-0"><p class="text-muted">{{ $t("REFUND.CREATE.PAYMENT_DATE")}}</p></CCol>
                    <CCol col="8" class="pl-0"><p class="font-weight-bold">{{ this.moment(paymentDate).format("DD MMM YYYY HH:mm") }}</p></CCol>
                  </CRow>
                </CCol>
                <CCol col="6">
                  <CRow class="col-detail">
                    <CCol col="6" class="pr-0"><p class="text-muted ml-5 px-0">{{ $t("REFUND.CREATE.PAYMENT_AMOUNT") }}</p></CCol>
                    <CCol col="6" class="pl-0"><p class="font-weight-bold">Rp {{ formatAmount(paymentAmount) }}</p></CCol>
                    <CCol col="6" class="pr-0" v-show="customerId !== ''">
                      <p class="text-muted ml-5 px-0" >{{ $t("REFUND.LABEL.CUSTOMER_ID") }}</p>
                    </CCol>
                    <CCol col="6" class="pl-0" v-show="customerId !== ''">
                      <p class="font-weight-bold text-link  color-blue" @click="goTo(`/customers/${customerId}`)">{{ customerId }}</p>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol col="12">
                  <p class="mb-1">{{ $t("REFUND.CREATE.REFUNDABLE_AMOUNT")}}</p>
                  <p class="font-weight-bold h5 mb-3">
                    Rp {{ formatAmount(refundableAmount) }}
                  </p>
                  <CInput
                      v-show="customerId === ''"
                      v-model="customerName"
                      class="col-12 px-0"
                      :label="$t('REFUND.LABEL.CUSTOMER_NAME')"
                  />
                  <CInput
                      v-show="customerId === ''"
                      class="col-12 px-0"
                      v-model="customerPhone"
                      :label="$t('REFUND.LABEL.CUSTOMER_PHONE')"
                  />
                  <CInput
                      v-show="customerId === ''"
                      class="col-12 px-0"
                      v-model="customerEmail"
                      :label="$t('REFUND.LABEL.CUSTOMER_EMAIL')"
                  />
                  <CInput
                      class="col-12 px-0"
                      addInputClasses="input-refund-amount"
                      v-model="refundAmount"
                      type="number"
                      :label="$t('REFUND.LABEL.REFUND_AMOUNT')"
                      :description="$t('REFUND.LABEL.REFUND_AMOUNT_DESC')"
                  />
                  <CInput
                      class="col-12 px-0"
                      v-model="refundNotes"
                      :label="$t('REFUND.LABEL.REFUND_NOTES')"
                  />
                </CCol>
              </CRow>
            </div>
          </CForm>
        </CCardBody>
        <CCardFooter v-show="showPaymentDetail" class="footer-createRefund">
          <CRow class="float-right">
            <CCol sm class="text-center">
              <CButton @click="goBack">
                {{ $t("COMMON.BACK_BUTTON") }}
              </CButton>
              <CButton
                type="submit"
                color="durianprimary"
                @click="handleSubmit"
                v-element-access="permissions.refundCreate"
              >
                {{ $t("REFUND.CREATE.ASK_APPROVAL_BUTTON") }}
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
    <CModal
        color="light"
        :no-close-on-backdrop="false"
        :centered="true"
        :show.sync="openSuccessModal"
    >
      <template #header-wrapper>
        <div class="px-2 py-2"></div>
      </template>
      <CRow align-horizontal="center">
        <img class="mx-auto" src="../../assets/img/success.svg" />
        <CCol col="12">
          <p class="mt-4 h5 text-center font-weight-bold">{{ $t("REFUND.CREATE.SUCCESS_TITLE") }}</p>
          <p class="text-center text-muted">
            {{ $t("REFUND.CREATE.SUCCESS_DESCRIPTION") }}
            <span class="refund-link" @click="goTo(`/refunds/${refundId}`)">{{ refundId }}</span>
          </p>
        </CCol>
      </CRow>
      <template #footer>
        <div class="px-2 py-2">
          <CButton @click="goTo('/refunds')" color="durianprimary">
            {{ $t("REFUND.CREATE.GO_TO_REFUND_LIST") }}
          </CButton>
        </div>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import { getPayment } from "@/api/payment.api.js";
import { createCustomer } from "@/api/customer.api.js";
import { createRefund, getRefundableAmount } from "@/api/refunds.api.js";
const statusCompleted = 'completed', excludeTypePayment = 'card_details';

export default {
  name: "NewRefund",
  data() {
    return {
      showSearchPaymentForm: true,
      inputPaymentId: null,
      paymentId: null,
      paymentDate: null,
      paymentAmount: 0,
      customerId: "",
      customerName: null,
      customerPhone: null,
      customerEmail: null,
      refundableAmount: 0,
      refundAmount: "",
      refundNotes: "",
      refundType: "partial",
      showPaymentDetail: false,
      showNotFound: false,
      openSuccessModal: false,
      refundId: null,
    }
  },
  methods: {
    handleSearch() {
      this.showNotFound = false;
      this.showPaymentDetail = false;
      this.fetchPaymentDetail();
    },
    fetchPaymentDetail(){
      getRefundableAmount(this.inputPaymentId)
          .then((response) => {
            if(response && response.data && response.data.data === "")
              response.data.data = "0"
            const err = this.verifyHttpResponse(response);
            if (err === false) {
              const { data } = response.data;
              this.refundableAmount = data;
            } else {
              this.showToaster(this.$t("ERROR_GETTING_REFUNDABLE_AMOUNT"))
            }
          })
          .then(() => {
            getPayment(this.inputPaymentId, this.generateExpandParams(["order"]))
                .then((response) => {
                  const err = this.verifyHttpResponse(response);
                  if (err === false) {
                    const { data } = response.data;
                    if (data.status === statusCompleted && data.payment_details_type !== excludeTypePayment) {
                      this.paymentId = data.id;
                      this.paymentDate = data.created_at;
                      this.paymentAmount = data.amount;
                      this.refundAmount = parseInt(this.refundableAmount).toString();
                      if (data.order) {
                        this.customerId = data.order.customer_id;
                      }
                      this.showPaymentDetail = true;
                    } else {
                      this.showToaster(this.$t("PAYMENT_NOT_ELIGIBLE_FOR_REFUND"))
                    }
                  } else {
                    this.showToaster("Payment not found")
                  }
                })
                .catch(() => this.showNotFound = true);
          }).catch(() => this.showNotFound = true);
    },
    async handleEmptyCustomer() {
      const { data }= await createCustomer({
        given_name: this.customerName,
        email: this.customerEmail,
        mobile: this.customerPhone,
      });

      this.customerId = data.data.id;
    },
    async handleSubmit() {
      if (!this.customerId) {
        await this.handleEmptyCustomer();
      }

      const refundAmount = parseInt(this.refundAmount);
      if(this.refundAmount === null) {
        this.showToaster("Amount can't empty")
      } else
      if(refundAmount < 10000 || refundAmount > this.paymentAmount) {
        this.showToaster("Amount of Refund should not be less than Rp10.000 or greater than payment amount")
      } else {
        createRefund({
          payment_id: this.paymentId,
          customer_id: this.customerId,
          amount: this.refundAmount,
          notes: this.refundNotes,
          type: this.refundType,
        })
            .then(res => {
              this.refundId = res.data.data.RefundID;
              this.openSuccessModal = !this.openSuccessModal;
              this.fetchPaymentDetail();
            })
            .catch(err => this.showToaster(err.response.data.error));
      }
    }
  },
  mounted() {
    const paymentId = this.$route.query.payment_id;
    if(paymentId) {
      this.inputPaymentId = paymentId;
      this.showSearchPaymentForm = false;
      this.fetchPaymentDetail();
    }
  }
};
</script>
<style>
.primary-purple {
  border-color: #725CF6;
  color: #725CF6;
}

.refund-link {
  color: #725CF6;
  cursor: pointer;
}

.refund-link:hover {
  text-decoration: underline;
}

.col-detail p {
  margin-bottom: 0.5em;
}

/* Chrome, Safari, Edge, Opera */
.input-refund-amount::-webkit-outer-spin-button,
.input-refund-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-refund-amount[type=number] {
  -moz-appearance: textfield;
}

.text-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

</style>